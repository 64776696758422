//@ts-check
import React, { useContext, useState } from "react"
import { Link, navigate } from "gatsby"

import { Button, Checkbox, Form } from "antd"
import { Auth } from "aws-amplify"
import { AuthContext } from "../../context/AuthContext"
import { isBrowser } from "../../utils/SSR"

const LoginForm = () => {
  const authContext = useContext(AuthContext)
  const [error, setError] = useState("")

  const loginSubmit = async ({ password, email, remember }) => {
    try {
      await Auth.signIn({
        username: email.toLowerCase(),
        password,
      })
      console.log("loggin success")

      if (remember) {
        isBrowser() &&
          window.localStorage.setItem("savedEmailLogin", email.toLowerCase())
      } else {
        isBrowser() && window.localStorage.setItem("savedEmailLogin", "")
      }

      navigate("/my-account")
    } catch (e) {
      setError("Unable to log in with that email and password")
    }
  }

  const loginSubmitFailed = () => {
    console.log("form submit failed")
  }

  const defaultEmail =
    (isBrowser() && window.localStorage.getItem("savedEmailLogin")) || ""

  return (
    <div className="login-form">
      <h2>Login</h2>

      <Form
        name="basic"
        wrapperCol={{ span: 24 }}
        onFinish={loginSubmit}
        onFinishFailed={loginSubmitFailed}
        autoComplete="off"
        layout="vertical"
      >
        <Form.Item
          label="Email"
          className="form-group"
          name="email"
          initialValue={defaultEmail}
          rules={[
            {
              type: "email",
              message: "The input is not valid E-mail!",
            },
            { required: true, message: "Please input your email!" },
          ]}
        >
          <input
            type="text"
            className="form-control"
            placeholder="Email Address"
            onChange={() => setError("")}
          />
        </Form.Item>

        <Form.Item
          //label="Email"
          className="form-group"
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <input
            type="password"
            className="form-control"
            placeholder="Password"
            onChange={() => setError("")}
          />
        </Form.Item>

        <Form.Item
          name="remember"
          valuePropName="checked"
          initialValue={!!defaultEmail}
        >
          <Checkbox>Remember me</Checkbox>
        </Form.Item>

        <div className="col-lg-6 col-md-6 col-sm-6 lost-your-password-wrap">
          <Link to="/forgot-password" className="lost-your-password">
            Lost your password?
          </Link>
        </div>
        <Form.Item>
          <Button htmlType="submit" style={{ height: "50px" }}>
            Log In
          </Button>
        </Form.Item>
        {error && <div>{error}</div>}
      </Form>
    </div>
  )
}

export default LoginForm
