//@ts-check
import { Button, Form } from "antd"
import { navigate } from "gatsby"
import React, { useContext, useState } from "react"
import { AuthContext } from "../../context/AuthContext"
import { Auth } from "aws-amplify"
import { isBrowser } from "../../utils/SSR"

const RegisterForm = () => {
  const authContext = useContext(AuthContext)
  const [error, setError] = useState("")

  const signupSubmit = async ({ password, email, confirmpassword }) => {
    if (password !== confirmpassword) {
      setError("The passwords do not match")
      return
    }
    try {
      await Auth.signUp({
        username: email.toLowerCase(),
        password,
        attributes: {
          email: email.toLowerCase(),
        },
        autoSignIn: {
          // optional - enables auto sign in after user is confirmed
          enabled: true,
        },
      })

      isBrowser() && window.localStorage.setItem("confirmEmail", email)
      const search = window.location.search
      navigate(`/confirm-email${search}`)
    } catch (error) {
      setError(error.message)
    }
  }

  const signupSubmitFailed = () => {
    setError("Unable to signup")
  }

  return (
    <div className="register-form">
      <h2>Register</h2>

      <Form
        name="basic"
        wrapperCol={{ span: 24 }}
        onFinish={signupSubmit}
        onFinishFailed={signupSubmitFailed}
        autoComplete="off"
        layout="vertical"
        style={{ color: "white" }}
      >
        <Form.Item
          label="Email"
          className="form-group"
          name="email"
          rules={[
            {
              type: "email",
              message: "The input is not valid E-mail!",
            },
            { required: true, message: "Please input your email!" },
          ]}
        >
          <input
            type="text"
            className="form-control"
            placeholder="Email Address"
            onChange={() => setError("")}
          />
        </Form.Item>

        <Form.Item
          //label="Email"
          className="form-group"
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Please confirm your password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve()
                }
                return Promise.reject(
                  new Error("The two passwords that you entered do not match!")
                )
              },
            }),
          ]}
          dependencies={["password"]}
        >
          <input
            type="password"
            className="form-control"
            placeholder="Password"
            pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
            title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
            onChange={() => setError("")}
          />
        </Form.Item>
        <Form.Item
          //label="Email"
          className="form-group"
          label="Confirm Password"
          name="confirmpassword"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <input
            type="password"
            className="form-control"
            placeholder="Password"
            onChange={() => setError("")}
          />
        </Form.Item>

        <Form.Item>
          <Button htmlType="submit" style={{ height: "50px" }}>
            Sign Up
          </Button>
        </Form.Item>
        {error && <div>{error}</div>}
      </Form>
    </div>
  )
}

export default RegisterForm
